<template>
  <div class="deal">

    <div class="wrap">

      <div class="a1"></div>

      <div class="a2">

        <!-- Error -->
        <div class="error" v-if="error">
          <div class="logo"><a href="https://stunii.com">
            <img
                src="./../assets/logo.png" height="80px" alt="STUNii"/>
          </a>
            <h3>Oh no!</h3>
            <div>That deal got away... but don't miss any others - grab the STUNii app</div>
            <div class="apps">
              <div>Download <span class="brandtext">STUNii</span> app now for more<br>great offers and freebies</div>
              <a href="https://play.google.com/store/apps/details?id=obllivionsoft.djole.nis.rs.stusdeals&hl=en_GB&gl=US"><img
                  class="store"
                  src="./../assets/google.png" alt="Google Play" height="50px"/></a>
              <a href="https://apps.apple.com/gb/app/stunii-unbeatable-discounts/id1281476467"><img class="store"
                                                                                                    src="./../assets/apple.png"
                                                                                                    alt="App Store"
                                                                                                    height="50px"/></a>
            </div>
          </div>
        </div>

        <!-- Loading -->
        <div class="loading" v-else-if="loading">
          <div class="logo"><a href="https://stunii.com">
            <img
                src="./../assets/logo.png" height="80px" alt="STUNii"/>
          </a>
          </div>
          <img class="loader"
               src="./../assets/loading.gif" alt="loading"/>
          <br/>
          Generating a code just for you 😉
        </div>

        <!-- Loaded -->
        <div class="loaded" v-else>
          <div class="brand">
            <img
                :src="deal.logo" v-if="deal.logo" height="120px"/>
          </div>
          <p>Your unique offer code is</p>
          <span class="code">{{ deal.code }}</span>
          <div class="activate" @click="visitPartner"><span v-if="!copied">Copy and go to</span><span
              v-else>👍 Going to</span>
            {{ deal.name }}
          </div>

          <div class="social">
            <div>Don't leave your mates out<br>Share this awesome offer</div>
            <div class="networks">
              <a :href="socialLink('whatsapp')" class="socmed"><img
                  src="./../assets/socmed/4x/whatsapp.png" alt="WhatsApp" width="60px"/></a>
              <a :href="socialLink('facebook')" class="socmed"><img
                  src="./../assets/socmed/4x/facebook.png" alt="Twitter" width="60px"/></a>
              <a :href="socialLink('twitter')" class="socmed"><img
                  src="./../assets/socmed/4x/twitter.png" alt="Facebook" width="60px"/></a>
            </div>
          </div>

          <div class="apps">
            <div>Download <span class="brandtext">STUNii</span> app now for more<br>great offers and freebies</div>
            <a href="https://play.google.com/store/apps/details?id=obllivionsoft.djole.nis.rs.stusdeals&hl=en_GB&gl=US"><img
                class="store"
                src="./../assets/google.png" alt="Google Play" height="50px"/></a>
            <a href="https://apps.apple.com/gb/app/stunii-unbeatable-discounts/id1281476467"><img class="store"
                                                                                                  src="./../assets/apple.png"
                                                                                                  alt="App Store"
                                                                                                  height="50px"/></a>
          </div>
        </div>
      </div>

      <div class="a3"></div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Deal',
  data: function () {
    return {
      loading: true,
      dealID: null,
      deal: {
        id: '',
        uri: '',
        logo: '',
        code: '',
        name: ''
      },
      copied: false,
      error: false
    }
  },
  metaInfo() {
    return {
      title: this.deal.name,
      titleTemplate: '%s @ STUnii'
    }
  }
  ,
  methods: {
    socialLink: function (network) {
      if (network === 'facebook') {
        return `https://www.facebook.com/sharer/sharer.php?u=https://deal.stunii.com/${this.deal.id}`
      } else if (network === 'twitter') {
        return `http://twitter.com/share?text=${this.deal.name}&url=https://deal.stunii.com/${this.deal.id}&hashtags=STUnii,deal`
      } else if (network === 'whatsapp') {
        return `https://api.whatsapp.com/send?phone=&text=${this.deal.name}: https://deal.stunii.com/${this.deal.id}`
      }
    }
    ,
    getDeal: async function (dealID) {
      try {
        let vm = this;
        fetch(`https://api.stunii.com/codedeal/${dealID}/redeem?c=${Date.now()}`)
            .then(response => {

                  if (response.status === 200) {
                    response.json().then(function (data) {
                      vm.loading = false
                      vm.deal = data
                    })

                  } else {
                    vm.error = true
                  }

                }
            )
            .then(data => {

            })
      } catch (e) {
        this.error = true
      }
    }
    ,
    visitPartner: function () {
      let vm = this;
      this.$copyText(this.deal.code).then(function (e) {
        vm.copied = true
        setTimeout(function () {
          location.href = vm.deal.uri
        }, 1500);
      }, function (e) {
        alert('Sorry but copy is disabled in your browser, so make a note of the code before you continue!')
      })
    }
  }
  ,
  computed: {}
  ,
  created() {
    this.dealID = this.$route.params.id
    this.getDeal(this.dealID)
  }
}

</script>

<style>

@font-face {
  font-family: 'LEMON MILK';
  src: url('./../../public/font/LEMONMILK-Regular.eot');
  src: url('./../../public/font/LEMONMILK-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../../public/font/LEMONMILK-Regular.woff2') format('woff2'),
  url('./../../public/font/LEMONMILK-Regular.woff') format('woff'),
  url('./../../public/font/LEMONMILK-Regular.ttf') format('truetype'),
  url('./../../public/font/LEMONMILK-Regular.svg#LEMONMILK-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  /**  background: #e66825 !important; */
  font-family: 'LEMON MILK', sans-serif;
  background: linear-gradient(45deg, #f3c614, #e22427) no-repeat bottom;
  background-size: cover;
  min-height: 100vh;
  color: #000000;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.a1 {
  max-width: 50%;
}

.a2 {
  align-self: flex-start;
}

.a3 {
  align-self: flex-end;
}

.wrap {

  text-align: center;
  background: #FFFFFF;
  padding: 10px;
  border-radius: 7px;
  -webkit-box-shadow: 9px 9px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 9px 9px 5px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 9px 9px 5px 0px rgba(0, 0, 0, 0.35);

}

.loading, .error, .loaded {
  padding: 20px;
}

.brand img {
  margin-top: -100px;
  border-radius: 10px;
}

.loaded .code {
  padding: 10px;
  background: #f3c614;
  margin: 10px;
  border-radius: 7px;
  text-transform: none;
}

.loaded .activate {
  padding: 10px;
  background: black;
  color: white;
  margin: 20px;
  cursor: pointer;
  border-radius: 7px;
}

.brandtext {
  color: orangered;
  text-transform: none;
  font-weight: bold;
}

.networks img {
  padding: 4px;
}

.apps img {
  padding: 4px;
}

.social {
  padding-top: 10px;
}

.apps {
  padding-top: 10px;
}

</style>